import "../App.css";
import "../css/Comman.css";
import "../css/HoneyMoon.css";

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";

import Navigation from "./Navigation";
import Footer from "./Footer";

const Packages = () => {
  const { id } = useParams();
  console.log(id);
  let isLoading = false;
  var renderAfterCalled = useRef(false);
  let API = `https://webapi.toucanleisures.com/package_details/${id}`;

  const [mediaList1, setMediaList1] = useState([]);

  const fecthApiData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      setMediaList1(data.data);
      console.log(data.data);
      isLoading = true;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fecthApiData(API);
      renderAfterCalled.current = true;

      window.scrollTo(0, 0);
    }
  });
  useEffect(() => {
    document.title = "Toucan Leisures - Packages";
  }, []); //

  let findDetails1 = mediaList1[0];

  // if (findDetails1.length >= 1) {
  //   isLoading = true;
  // }
  isLoading = true;
  console.log(findDetails1);
  let findDetails = mediaList1;
  // console.log(findDetails1.image1);
  // Enquiry form validation Start

  // ==================== Form - Validation - Start ====================
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [travelercount, setTravelercount] = useState("");
  const [travelercountError, setTravelercountError] = useState("");
  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);

    if (!newName) {
      setNameError("Name is required.");
    } else {
      setNameError("");
    }
  };
  const handleTravelercountChange = (event) => {
    const newTravelercount = event.target.value;
    setTravelercount(newTravelercount);

    if (!newTravelercount) {
      setTravelercountError("Traveler count is required.");
    } else {
      setTravelercountError("");
    }
  };
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDate(newDate);

    if (!newDate) {
      setDateError("Date is required.");
    } else {
      setDateError("");
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!newEmail) {
      setEmailError("Email is required.");
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newEmail)
    ) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneChange = (event) => {
    const newPhone = event.target.value;
    if (/^\d{0,10}$/.test(newPhone)) {
      setPhone(newPhone);
      setPhoneError("");
    } else {
      setPhoneError("Please enter valid mobile number");
    }
  };

  const handleButtonClick = () => {
    if (name && email && phone && travelercount && date) {
      // Perform any necessary actions with the form data
      // Refresh the page
      window.location.reload();
    } else {
      if (!name) setNameError("Please enter name is required.");
      if (!email) setEmailError("Please enter emailid is required.");
      if (!travelercount)
        setTravelercountError("Please enter traveler count is required.");
      if (!date) setDateError("Please select date is required.");
      if (!phone) setPhoneError("Please enter mobile number is required.");
    }
  };

  // ==================== Form - Validation - End ====================

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zlk6u0f",
        "template_ihvmsp7",
        form.current,
        "EXKLoGJ6ka8d4hWqY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  // Enquiry form validation End

  // Page Loading Start
  const [isLoadings, setIsLoadings] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      // Simulate a delay to show loading state
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Replace the following with your actual data fetching logic
      const response = await fetch("your-api-url-here");
      const jsonData = await response.json();

      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchData()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => {
        setIsLoadings(false);
      });
  }, []);

  if (isLoadings) {
    return (
      <div>
        <div className="page_loading_animation">
          <div className="loading_animation"></div>
        </div>
      </div>
    );
  }
  // Page Loading End

  // Summary Details Start
  // Fetch all details element
  const details = Array.from(document.querySelectorAll("details"));
  const removeAttribute = (e) => {
    e.preventDefault();
  };
  // Add onclick listeners
  details.forEach((targetDetail) => {
    targetDetail.addEventListener("click", () => {
      // Close all details that are not targetDetail
      details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.removeAttribute("open");
        }
      });
    });
  });
  // Summary Details End
  if (isLoading) {
    // document.title = findDetails1[0];
    return (
      <div className="ThailandTourPackagesPlan">
        <div>
          <Navigation />

          <div className="thailand-home enquiry-from-thailand">
            <h4 className="head-2">{findDetails1.title}</h4>
            <h1 className="head-1">{findDetails1.package}</h1>
          </div>
          <div className="thailand-honey-pack pt-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-9 col-sm-12">
                  <div className="leftDiv">
                    <div id="page-top">
                      <div id="wrapper">
                        <div
                          id="content-wrapper"
                          className="container-fluid d-flex flex-column"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="container">
                                <div className="tab-pane fade show active">
                                  <div class="accordion" id="accordionExample">
                                    <h1>Itinerary</h1>
                                    {/* {findDetails1.itinerary.map(
                                      (item, index) => (
                                        <div className="summary_details">
                                          <details
                                            className="accordion-item"
                                            key={index}
                                          >
                                            <summary
                                              class="accordion-button collapsed"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseOne"
                                              aria-expanded="false"
                                              aria-controls="collapseOne"
                                            >
                                              <span className="days">
                                                Day 1 {item.day}
                                              </span>
                                            </summary>
                                            <hr />
                                            <p>
                                              <div className="row">
                                                <div className="col-lg-1 col-md-1 col-sm-12">
                                                  <div className="vr-line"></div>
                                                </div>
                                                <div className="col-lg-11 col-md-11 col-sm-12 ">
                                                  <h4>
                                                    Other Benefits (On Arrival)
                                                  </h4>
                                                  <p>
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.description,
                                                      }}
                                                    ></span>
                                                  </p>
                                                  <p className="Indulge">
                                                    Indulge in dazzling
                                                    performances
                                                  </p>

                                                  <div>
                                                    {item.image.map(
                                                      (item1, index1) => (
                                                        <img
                                                          src={
                                                            findDetails1.image_url +
                                                            item1
                                                          }
                                                          alt=""
                                                        />
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </p>
                                          </details>
                                        </div>
                                      )
                                    )} */}
                                    <div class="accordion">
                                      {findDetails1.itinerary.map(
                                        (item, index) => (
                                          <details
                                            className="accordion-item"
                                            key={index}
                                          >
                                            <summary
                                              class="accordion-button collapsed"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#collapseOne"
                                              aria-expanded="false"
                                              aria-controls="collapseOne"
                                            >
                                              <span className="days">
                                                Day 1 {item.day}
                                              </span>
                                            </summary>
                                            <div className="row py-4">
                                              <div className="col-lg-1 col-md-1 col-sm-12">
                                                <div className="vr-line"></div>
                                              </div>
                                              <div className="col-lg-11 col-md-11 col-sm-12 ">
                                                <h4>
                                                  Other Benefits (On Arrival)
                                                </h4>
                                                <p>
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.description,
                                                    }}
                                                  ></span>
                                                </p>
                                                <p className="Indulge">
                                                  Indulge in dazzling
                                                  performances
                                                </p>

                                                <div>
                                                  {item.image.map(
                                                    (item1, index1) => (
                                                      <img
                                                        src={
                                                          findDetails1.image_url +
                                                          item1
                                                        }
                                                        alt=""
                                                      />
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </details>
                                        )
                                      )}
                                    </div>
                                  </div>

                                  <div className="Highlights mt-3">
                                    <h1>Highlights</h1>
                                    <ul>
                                      <ol>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: findDetails1.conditions,
                                          }}
                                        ></span>
                                      </ol>
                                    </ul>
                                  </div>
                                  <div class="tabControl">
                                    <div class="container p-0">
                                      <ul
                                        class="nav nav-pills nav-fill"
                                        id="pills-tab"
                                        role="tablist"
                                      >
                                        <li class="nav-item">
                                          <a
                                            class="nav-link active"
                                            id="pills-home-tab"
                                            data-toggle="pill"
                                            href="#pills-1"
                                            role="tab"
                                          >
                                            Inclusions
                                          </a>
                                        </li>
                                        <li class="nav-item">
                                          <a
                                            class="nav-link"
                                            id="pills-profile-tab"
                                            data-toggle="pill"
                                            href="#pills-2"
                                            role="tab"
                                          >
                                            Exclusions
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="tab-content"
                                      id="pills-tabContent"
                                    >
                                      <div
                                        class="tab-pane tabs-panes fade show active"
                                        id="pills-1"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                      >
                                        <ul>
                                          <ol>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: findDetails1.inclusions,
                                              }}
                                            ></span>
                                          </ol>
                                        </ul>
                                      </div>
                                      <div
                                        class="tab-pane fade"
                                        id="pills-2"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                      >
                                        <ul>
                                          <ol>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: findDetails1.exclusions,
                                              }}
                                            ></span>
                                          </ol>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-3 col-sm-12">
                  <div className="rightDiv">
                    <div className="scrollContent">
                      <div className="price-card">
                        {/* <h3>Booking Details</h3> */}
                        <h4>{findDetails1.title}</h4>

                        <h6>Super Deal Price</h6>
                        {/* <p className="start-from">Starts From</p> */}

                        <p className="off-price">
                          ₹ {findDetails1.price}/- <span>₹ 70,000/-</span>{" "}
                        </p>
                        <h5>
                          <i class="fa-regular fa-clock"></i>&nbsp;{" "}
                          {findDetails1.nights}Days / {findDetails1.nights - 1}
                          Nights
                        </h5>
                        {/* <p className="per-per">Per Person On Twin Sharing</p> */}
                        <div>
                          <button
                            type="button"
                            class="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <div class="modal-body">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-lg-6 bg-images">
                        <h5>
                          <span>{findDetails1.title}</span>
                          {/* <span>6 Days / {findDetails1.nights} Nights </span> */}
                        </h5>
                        <div className="offers">
                          <h6>offer</h6>
                          <h6>&#x20B9; {findDetails1.price}</h6>
                          <h4>&#x20B9; 70,000/-</h4>
                        </div>
                        <div class="geometric-form"></div>
                      </div>
                      <div className="col-lg-6 bg-white">
                        <form ref={form} onSubmit={sendEmail}>
                          <h4>ENQUIRY FORM</h4>

                          <div class="form-group">
                            <input
                              // value={formData.to_name || ""}
                              class="form-control"
                              type="text"
                              id="txtNumeric"
                              className="form-control"
                              placeholder="Name"
                              onChange={handleNameChange}
                              name="user_name"
                            />
                            {nameError && (
                              <div className="modals_error">{nameError}</div>
                            )}
                          </div>
                          <div class="form-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email Id"
                              onChange={handleEmailChange}
                              name="user_email"
                            />
                            {emailError && (
                              <div className="modals_error">{emailError}</div>
                            )}
                          </div>
                          <div class="form-group">
                            <input
                              type="text"
                              className="form-control"
                              maxlength="10"
                              data-gtm-form-interact-field-id="1"
                              data-parsley-type="number"
                              data-parsley-maxlength="10"
                              data-parsley-minlength="10"
                              placeholder="Phone Number   "
                              name="mobile"
                              onChange={handlePhoneChange}
                            />
                            {phoneError && (
                              <div className="modals_error">{phoneError}</div>
                            )}
                          </div>
                          <div class="form-group">
                            <input
                              className="form-control"
                              placeholder="No. of Travelers *"
                              data-parsley-type="number"
                              type="text"
                              name="adult"
                              onChange={handleTravelercountChange}
                            />
                            {travelercountError && (
                              <div className="modals_error">
                                {travelercountError}
                              </div>
                            )}
                          </div>

                          <div class="form-group">
                            <input
                              className="form-control"
                              id="date"
                              type="date"
                              placeholder="Trip Date*"
                              autocomplete="off"
                              name="tdate"
                              onChange={handleDateChange}
                            />{" "}
                            {dateError && (
                              <div className="modals_error">{dateError}</div>
                            )}
                          </div>

                          <div class="form-group">
                            <textarea
                              class="form-control"
                              rows="4"
                              name="message"
                              placeholder="Your Message.."
                              required=""
                            ></textarea>
                          </div>
                          <div class="form-group enq-btn">
                            <input
                              placeholder="Submit"
                              class=" enquiry-button"
                              value="Send"
                              type="submit"
                              onClick={handleButtonClick}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
};
export default Packages;
