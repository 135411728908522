import "../App.css";
import "../css/HoneyMoon.css";
import "../css/Thailand.css";
import "../css/Comman.css";

import React, { useEffect, useState, useRef, useReducer } from "react";
// useNavigate / useParams
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Navigation from "./Navigation";
import Footer from "./Footer";

const Interest = () => {
  // Api call Start
  var renderAfterCalled = useRef(false);
  const { id } = useParams();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  let API = `https://webapi.toucanleisures.com/type/${id}`;
  const [mediaList, setMediaList] = useState([]);
  const fecthApiData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      console.log(data);
      setMediaList(data.data);
    } catch (error) {
      console.log(error);
    }
    forceUpdate();
  };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fecthApiData(API);
      renderAfterCalled.current = true;
      document.title = "Toucan Leisures - Package";
      window.scrollTo(0, 0);
    }
  }, [reducerValue]);

  let findDetails = mediaList;
  let Navigate = useNavigate();
  // Api call End

  // Page Loading Start
  const [isLoadings, setIsLoadings] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      // Simulate a delay to show loading state
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Replace the following with your actual data fetching logic
      const response = await fetch("your-api-url-here");
      const jsonData = await response.json();

      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchData()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => {
        setIsLoadings(false);
      });
  }, []);

  if (isLoadings) {
    return (
      <div>
        {" "}
        <div className="page_loading_animation">
          <div className="loading_animation"></div>
        </div>
      </div>
    );
  }
  // Page Loading End
  return (
    <div className="ThailandTourPackages">
      <Navigation />
      <div className="thailand-home">
        <h1>Thailand Vacation Packages</h1>
        <p>
          Discover ancient ruins and relaxing beach resorts and buddhist temples
        </p>
      </div>
      <div className="Thailand-content margin">
        <div className="container">
          <h1 className="head-content">Thailand Vacation Packages</h1>
          <p className="head-para-content">
            A fun-filled, brimming with culture, history and natural wonders,
            this Land of a Thousand Smiles is ripe with countless travel
            temptations and with the advent of Thailand Tourism, it has emerged
            as a magnet for tourists across the world. This kingdom of amazement
            truly is a wondrous place to endure it all. You will be able to
            discover some of the spectacular tourist places in Thailand that
            will make you go in awe of this vivid South-East Asian jewel.
          </p>
        </div>
      </div>

      {findDetails.map((data, index) => {
        // document.title = findDetails[0].country;
        return (
          <div className="tour-packages" id="Tour-Packages">
            <div className="container ">
              <div className="tour-package-card margins">
                <div
                  key={index}
                  className="row"
                  onClick={() => Navigate(`/Packages/${data.id}`)}
                >
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    {/* <img src={data.image} alt="" /> */}
                    <img src={data.image} alt="" />
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12 content-section">
                    <h5>{data.title}</h5>
                    <ul>
                      <li> </li>
                      <strong>
                        {data.nights} Nights / {data.nights + 1} Days
                      </strong>
                    </ul>
                    <div className="include-activity">
                      <div>
                        <h6>INCLUSIONS (CUSTOMISABLE)</h6>
                        <ul>
                          <span>
                            {data.meals ? (
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                            ) : (
                              <span class="material-symbols-outlined">
                                dangerous
                              </span>
                            )}
                            Meals
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span>
                            {data.Activities ? (
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                            ) : (
                              <span class="material-symbols-outlined">
                                dangerous
                              </span>
                            )}
                            Activities
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span>
                            {data.Activities ? (
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                            ) : (
                              <span class="material-symbols-outlined">
                                dangerous
                              </span>
                            )}
                            Hotel
                          </span>
                          <br />
                          <span>
                            {data.Transport ? (
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                            ) : (
                              <span class="material-symbols-outlined">
                                dangerous
                              </span>
                            )}
                            Transport
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>
                            {data.Flights ? (
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                            ) : (
                              <span class="material-symbols-outlined">
                                dangerous
                              </span>
                            )}
                            Flights
                          </span>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="rate-deal">
                      <h4>Super Deal Price</h4>
                      <h6>
                        <strong>Starts From</strong>
                      </h6>
                      <p>
                        ₹ {data.offer}/-
                        <span>
                          <br />₹ {data.price}/-
                        </span>
                      </p>
                      <h6> Per Person On Twin Sharing </h6>
                      <button className="view-deal">More Details</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Footer />
    </div>
  );
};

export default Interest;
