import React, { useState } from "react";

import "../App.css";
import "../css/Comman.css";

import logo from "../img/favicon.png";
import { Link } from "react-router-dom";
// Images
import thailand from "../img/thailand.avif";
import dubai from "../img/dubai.avif";
import Malaysian from "../img/Malaysian.jpg";
import Srilankha from "../img/Srilankha.avif";
import Kashmir from "../img/Kashmir.jpg";

import thailand_1 from "../img/Thailand-1.jpg";
import dubai_1 from "../img/Dubai-1.jpg";
import Malaysian_1 from "../img/malaysia-1.jpg";
import Srilankha_1 from "../img/SINGAPORE-1.jpg";
import Kashmir_1 from "../img/Kashmir-1.jpg";

import International_Honeymoon_Packages from "../img/International Honeymoon Packages.jpg";
import International_Tour_Packages from "../img/International Tour Packages.jpg";
import Family_Packages from "../img/Family Packages.jpg";
import Budget_Packages from "../img/Budget Packages.jpg";
import Luxury_Packages from "../img/Luxury Packages.webp";

import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

export default function Navigation() {
  // const [showButton, setShowButton] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { id } = useParams();
  console.log(id);

  const [show] = useState(true);
  let Navigate = useNavigate();
  
  const navigateHoneymoon = () => {
    window.location.href = "/Package/honeymoon";
  };
  const navigateFriends = () => {
    window.location.reload = "/Package/friends";
  };
  const navigateFamily = () => {
    window.location.reload = "/Package/family";
  };
  const navigateSolo = () => {
    window.location.reload = "/Package/solo";
  };

  const navigateHoneymoon1 = () => {
    window.location.href = "/Honeymoon/honeymoon";
  };
  const navigateFriends1 = () => {
    window.location.reload = "/Honeymoon/friends";
  };
  const navigateFamily1 = () => {
    window.location.reload = "/Honeymoon/family";
  };
  const navigateSolo1 = () => {
    window.location.reload = "/Honeymoon/solo";
  };

  const navigateHoneymoon2 = () => {
    window.location.href = "/Interest/honeymoon";
  };
  const navigateFriends2 = () => {
    window.location.reload = "/Interest/friends";
  };
  const navigateFamily2 = () => {
    window.location.reload = "/Interest/family";
  };
  const navigateSolo2 = () => {
    window.location.reload = "/Interest/solo";
  };

  return (
    <div>
      <LoadingOverlay
        active={isActive}
        spinner
        text=""
        className="loading-overlay"
      ></LoadingOverlay>
      <div className="navigation-bar">
        <div className="App">
          <nav className="black">
            <div class="wrapper">
              <div class="logo">
                <a class="navbar-brand" href="/" alt="">
                  <img src={logo} alt="" />
                </a>
              </div>
              <input type="radio" name="slider" id="menu-btn" />
              <input type="radio" name="slider" id="close-btn" />
              <ul class="nav-links">
                <label for="close-btn" class="btn close-btn">
                  <i class="fas fa-times"></i>
                </label>
                <li>
                  <a class="navbar-brand black" href="/" alt="">
                    Home
                  </a>
                </li>

                <li>
                  <a class="desktop-item black">Tour Packages</a>
                  <input type="checkbox" id="showDrop" />
                  <label for="showDrop" class="mobile-item">
                    Tour Packages
                  </label>
                  <ul class="drop-menu drop-menu-1">
                    <li>
                      <Link
                        to={"/Package/honeymoon"}
                        onClick={navigateHoneymoon}
                        className="nav-link"
                      >
                        <span>
                          <img src={thailand} alt="" />
                        </span>
                        <span className="nav-content">
                          Thailand Tour package
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Package/family"}
                        onClick={navigateFamily}
                        className="nav-link"
                      >
                        <span>
                          <img src={dubai} alt="" />
                        </span>
                        <span className="nav-content">Bali Tour package</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Package/friends"}
                        onClick={navigateFriends}
                        className="nav-link"
                      >
                        <span>
                          <img src={Malaysian} alt="" />
                        </span>
                        <span className="nav-content">
                          Malaysia Tour package
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Package/solo"}
                        onClick={navigateSolo}
                        className="nav-link"
                      >
                        <span>
                          <img src={Srilankha} alt="" />
                        </span>
                        <span className="nav-content">
                          Singapore Tour package
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Package/honeymoon"}
                        onClick={navigateHoneymoon}
                        className="nav-link"
                      >
                        <span>
                          <img src={Kashmir} alt="" />
                        </span>
                        <span className="nav-content">
                          Kashmir Tour package
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a class="desktop-item black">Honeymoon Packages</a>
                  <input type="checkbox" id="showDrop" />
                  <label for="showDrop" class="mobile-item">
                    Honeymoon Packages
                  </label>
                  <ul class="drop-menu drop-menu-2">
                    <li>
                      <Link
                        to={"/Honeymoon/honeymoon"}
                        onClick={navigateHoneymoon1}
                        className="nav-link"
                      >
                        <span>
                          <img src={thailand_1} alt="" />
                        </span>
                        <span className="nav-content">
                          Thailand Honeymoon package
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Honeymoon/family"}
                        onClick={navigateFamily1}
                        className="nav-link"
                      >
                        <span>
                          <img src={dubai_1} alt="" />
                        </span>
                        <span className="nav-content">
                          Dubai Honeymoon package
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Honeymoon/friends"}
                        onClick={navigateFriends1}
                        className="nav-link"
                      >
                        <span>
                          <img src={Malaysian_1} alt="" />
                        </span>
                        <span className="nav-content">
                          Malaysia Honeymoon package
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Honeymoon/solo"}
                        onClick={navigateSolo1}
                        className="nav-link"
                      >
                        <span>
                          <img src={Srilankha_1} alt="" />
                        </span>
                        <span className="nav-content">
                          Singapore Honeymoon package
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Honeymoon/honeymoon"}
                        onClick={navigateHoneymoon1}
                        className="nav-link"
                      >
                        <span>
                          <img src={Kashmir_1} alt="" />
                        </span>
                        <span className="nav-content">
                          Kashmir Honeymoon package
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a class="desktop-item black">Packages by Interest</a>
                  <input type="checkbox" id="showDrop" />
                  <label for="showDrop" class="mobile-item">
                    Packages by Interest
                  </label>
                  <ul class="drop-menu drop-menu-3">
                    <li>
                      <Link
                        to={"/Interest/honeymoon"}
                        onClick={navigateHoneymoon2}
                        className="nav-link"
                      >
                        <span>
                          <img src={International_Honeymoon_Packages} alt="" />
                        </span>
                        <span className="nav-content">
                          International Tour Packages
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Interest/family"}
                        onClick={navigateFamily2}
                        className="nav-link"
                      >
                        <span>
                          <img src={International_Tour_Packages} alt="" />
                        </span>
                        <span className="nav-content">
                          International Honeymoon Packages
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Interest/friends"}
                        onClick={navigateFriends2}
                        className="nav-link"
                      >
                        <span>
                          <img src={Family_Packages} alt="" />
                        </span>
                        <span className="nav-content">Family Packages</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Interest/solo"}
                        onClick={navigateSolo2}
                        className="nav-link"
                      >
                        <span>
                          <img src={Budget_Packages} alt="" />
                        </span>
                        <span className="nav-content">Budget Packages</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Interest/family"}
                        onClick={navigateHoneymoon2}
                        className="nav-link"
                      >
                        <span>
                          <img src={Luxury_Packages} alt="" />
                        </span>
                        <span className="nav-content">Luxury Packages</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>

              <label for="menu-btn" class="btn menu-btn">
                <i class="fas fa-bars"></i>
              </label>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
