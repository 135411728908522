import "./App.css";
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  // HashRouter,
} from "react-router-dom";

import Home from "./components/Home";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import ThailandTourPackages from "./components/ThailandTourPackages";
import Packages from "./components/Packages";
import Package from "./components/Package";
import Booknow from "./components/Booknow";
import Honeymoon from "./components/Honeymoon";
import Interest from "./components/Interest";

function App() {
  return (
    <div className="App">
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Navigation" element={<Navigation />} />
            <Route path="/Footer" element={<Footer />} />
            <Route
              path="/ThailandTourPackages"
              element={<ThailandTourPackages />}
            />
            <Route path="/Interest/:id" element={<Interest />} />
            <Route path="/Honeymoon/:id" element={<Honeymoon />} />
            <Route path="/Packages/:id" element={<Packages />} />
            <Route path="/Package/:id" element={<Package />} />
            <Route path="/Booknow" element={<Booknow />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
