import "../App.css";
import React from "react";
import { Link } from "react-router-dom";

// images
import logo from "../img/favicon.png";

const navigateHoneymoon = () => {
  window.location.href = "/Package/honeymoon";
};
const navigateFriends = () => {
  window.location.reload = "/Package/friends";
};
const navigateFamily = () => {
  window.location.reload = "/Package/family";
};
const navigateSolo = () => {
  window.location.reload = "/Package/solo";
};

const navigateHoneymoon1 = () => {
  window.location.href = "/Honeymoon/honeymoon";
};
const navigateFriends1 = () => {
  window.location.reload = "/Honeymoon/friends";
};
const navigateFamily1 = () => {
  window.location.reload = "/Honeymoon/family";
};
const navigateSolo1 = () => {
  window.location.reload = "/Honeymoon/solo";
};

const navigateHoneymoon2 = () => {
  window.location.href = "/Interest/honeymoon";
};
const navigateFriends2 = () => {
  window.location.reload = "/Interest/friends";
};
const navigateFamily2 = () => {
  window.location.reload = "/Interest/family";
};
const navigateSolo2 = () => {
  window.location.reload = "/Interest/solo";
};

function Footer() {
  return (
    <div className="Footer margin">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-3 col-sm-12 footer-home-link">
            <h5>Tour Packages</h5>
            <Link
              to={"/Package/honeymoon"}
              onClick={navigateHoneymoon}
              className="navs-links"
            >
              Thailand Tour Packages
            </Link>
            <br />
            <Link
              to={"/Package/family"}
              onClick={navigateFamily}
              className="navs-links"
            >
              Bali Tour Packages
              <br />
            </Link>
            <Link
              to={"/Package/friends"}
              onClick={navigateFriends}
              className="navs-links"
            >
              Malaysia Tour Packages
              <br />
            </Link>
            <Link
              to={"/Package/solo"}
              onClick={navigateSolo}
              className="navs-links"
            >
              Singapore Tour Packages
            </Link>
            <br />
            <Link
              to={"/Package/honeymoon"}
              onClick={navigateHoneymoon}
              className="navs-links"
            >
              Kashmir Tour Packages
            </Link>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-12 footer-home-link">
            <h5>Honeymoon Packages</h5>
            <Link
              to={"/Honeymoon/family"}
              onClick={navigateFamily1}
              className="navs-links"
            >
              Thailand Honeymoon Packages
            </Link>
            <br />
            <Link
              to={"/Honeymoon/friends"}
              onClick={navigateFriends1}
              className="navs-links"
            >
              Bali Honeymoon Packages
              <br />
            </Link>
            <Link
              to={"/Honeymoon/friends"}
              onClick={navigateFriends1}
              className="navs-links"
            >
              Malaysia Honeymoon Packages
              <br />
            </Link>
            <Link
              to={"/Honeymoon/solo"}
              onClick={navigateSolo1}
              className="navs-links"
            >
              Singapore Honeymoon Packages
            </Link>
            <br />
            <Link
              to={"/Honeymoon/honeymoon"}
              onClick={navigateHoneymoon1}
              className="navs-links"
            >
              Kashmir Honeymoon Packages
            </Link>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-12 footer-home-link">
            <h5>Package By Interest</h5>
            <Link
              to={"/Interest/honeymoon"}
              onClick={navigateHoneymoon2}
              className="navs-links"
            >
              International Tour Packages
            </Link>
            <br />
            <Link
              to={"/Interest/family"}
              onClick={navigateFamily2}
              className="navs-links"
            >
              International Honeymoon Packages
              <br />
            </Link>
            <Link
              to={"/Interest/friends"}
              onClick={navigateFriends2}
              className="navs-links"
            >
              Family Packages
              <br />
            </Link>
            <Link
              to={"/Interest/solo"}
              onClick={navigateSolo2}
              className="navs-links"
            >
              Budget Packages
            </Link>
            <br />
            <Link
              to={"/Interest/family"}
              onClick={navigateHoneymoon2}
              className="navs-links"
            >
              Luxury Packages
            </Link>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-12 pt-5">
            <span className="footer_logo">
              <img src={logo} alt="" />
            </span>
            <p>
              Subscribe Easy To Make More Website Development Press The Bell
              Icon To Get Immediate Notifaction Of Latest Update
            </p>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-12 footer-address-link pt-5">
            <h5>Office</h5>
            <p>
              No.36, Medavakkam Main Road,
              <br /> Madipakkam,
              <br />
              Chennai, Tamil Nadu 600091.
            </p>
            <a href="mailto:toucanleisures@gmail.com">
              <i class="fa-solid fa-envelope"></i>&nbsp;
              toucanleisures@gmail.com
            </a>
            <br />
            <a href="tel:94984 77950">
              <i class="fa-solid fa-phone"></i>&nbsp; +91 94984 77950
            </a>
            <br />
            <a href="tel:99627 22292">
              <i class="fa-solid fa-phone"></i>&nbsp; +91 99627 22292
            </a>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 pt-5">
            <h5>Subscribe Now</h5>
            <form
              class="slides-form newsletter margin-top-5 desktop-margin-top-2"
              id="subscribe_newsletter_footer"
            >
              <input
                type="email"
                class="rounded cropLeft phone-wide email_address_field"
                name="user-email"
                placeholder="Enter your email..."
              />
              <button
                type="submit"
                class="button blue pale flat rounded phone-wide"
                name="button"
              >
                <span class="material-symbols-outlined">arrow_right_alt</span>
              </button>
            </form>
            <div class="hero">
              <div class="social-links">
                <a href="">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="">
                  <i class="fab fa-instagram"></i>
                </a>
                <a href="">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="">
                  <i class="fab fa-youtube"></i>
                </a>
                <a href="">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="copy-rights">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 copy-right-1">
              <h6>
                Copyright © 2023&nbsp;
                <a href="https://www.toucanleisures.com/" target="_blank">
                  Toucan Leisures
                </a>
                &nbsp;All Rights Reserved.
              </h6>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 copy-right-2">
              <h6>
                Powered By&nbsp;
                <a href="https://www.eximho.com/" target="_blank">
                  Eximho Private Limited
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
