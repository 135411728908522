import "../App.css";
import "../css/HoneyMoon.css";
import "../css/Thailand.css";
import "../css/Comman.css";

import p1 from "../img/Thailand/3/p1.webp";
import p2 from "../img/Thailand/3/p2.jpg";
import p6 from "../img/Thailand/3/p6.webp";

import React, { useEffect, useState, useRef, useReducer } from "react";
// useNavigate / useParams
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// images
import Navigation from "./Navigation";
import Footer from "./Footer";

function ThailandTourPackages() {
  // Api call Start
  var renderAfterCalled = useRef(false);

  const { id } = useParams();

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  // console.log(id);

  let API = `https://webapi.toucanleisures.com/type/honeymoon`;

  const [mediaList, setMediaList] = useState([]);

  const fecthApiData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      console.log(data);
      setMediaList(data.data);
    } catch (error) {
      console.log(error);
    }
    forceUpdate();
  };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fecthApiData(API);
      renderAfterCalled.current = true;
    }
    window.scrollTo(0, 0);
    document.title = "Toucan Leisures - ThailandTourPackages";
  }, [reducerValue]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let findDetails = mediaList;
  let Navigate = useNavigate();
  // Api call End

  // Page Loading Start
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      // Simulate a delay to show loading state
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Replace the following with your actual data fetching logic
      const response = await fetch("your-api-url-here");
      const jsonData = await response.json();

      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchData()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div>
        {" "}
        <div className="page_loading_animation">
          <div className="loading_animation"></div>
        </div>
      </div>
    );
  }
  // Page Loading End
  return (
    <div className="ThailandTourPackages">
      <div>
        <Navigation />
        <div className="thailand-home">
          <h1>Thailand Vacation Packages</h1>
          <p>
            Discover ancient ruins and relaxing beach resorts and buddhist
            temples
          </p>
          <a href="">
            <button className="head-button">
              View All Packages
              <span class="material-symbols-outlined">
                keyboard_double_arrow_down
              </span>
            </button>
          </a>
        </div>
        <div className="Thailand-content margin">
          <div className="container">
            <h1 className="head-content">Thailand Vacation Packages</h1>
            <p className="head-para-content">
              A fun-filled, brimming with culture, history and natural wonders,
              this Land of a Thousand Smiles is ripe with countless travel
              temptations and with the advent of Thailand Tourism, it has
              emerged as a magnet for tourists across the world. This kingdom of
              amazement truly is a wondrous place to endure it all. You will be
              able to discover some of the spectacular tourist places in
              Thailand that will make you go in awe of this vivid South-East
              Asian jewel.
            </p>
            <p className="head-para-content">
              Mainly Bangkok, Phi Phi Islands, and Phuket are the most included
              destinations in the Thailand tour packages. Apart from these, a
              lot of countryside destinations and little-known places like the
              UNESCO city of Ayutthaya, the gateway to the Golden Triangle
              Chiang Rai, Railay Beach or the secluded island of Koh Kood are
              coming into the limelight as the promising tourist spots.
            </p>
            <p className="head-para-content">
              If you keep your eyes open for the unknown and have the heart to
              explore the undiscovered, then there are a lot of things to do in
              Thailand that you can enjoy during your vacation here. The
              intriguing Thailand shopping, Scuba diving in the Andaman sea,
              Elephant bathing & feeding at several national parks, and visiting
              several Buddhist temples like Wat Phra & Wat Arun are a plateful
              of the attractions that suit everyone’s taste.
            </p>
          </div>
        </div>
        <div className="tour-packages">
          <div className="container ">
            <div className="tour-package-card margins">
              {findDetails.map((data, index) => {
                document.title = findDetails[0].country;
                return (
                  <div className="row">
                    <div key={index} className="col-lg-4 col-md-4 col-sm-12">
                      <img src={data.image} alt="" />
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-12 content-section">
                      <h5>Spectacular Krabi and Phuket Getaway</h5>
                      <ul>
                        <li>
                          Experience the most exciting short vacation in
                          Thailand. Climb rocks, explore mangrove forests in
                          Krabi and rejuvenate on the beautiful beaches of
                          Phuket.
                        </li>
                        <strong>5 Nights : 6 Days</strong>
                      </ul>
                      <div className="include-activity">
                        <div>
                          <h6>INCLUSIONS (CUSTOMISABLE)</h6>
                          <ul>
                            <span>
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                              2 Hotels
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                              4 activities
                            </span>
                            <br />
                            <span>
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                              1 Transfers
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                              <span class="material-symbols-outlined tick">
                                check_circle
                              </span>
                              2 Flights
                            </span>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="rate-deal">
                        <h4>Super Deal Price</h4>
                        <h6>
                          <strong>Starts From</strong>
                        </h6>
                        <p>
                          ₹51,498 /<span>46,498</span>
                        </p>
                        <h6> Per Person On Twin Sharing </h6>
                        <button
                          className="view-deal"
                          onClick={() =>
                            Navigate(
                              `/ThailandTourPackagesPlan1/${"thailand-6-day-package"}`
                            )
                          }
                        >
                          More Details
                        </button>
                        <button className="enquery view-deal">
                          <a target="_blank" href="https://wa.link/mvkem1">
                            Enquery Now
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="tour-packages">
          <div className="container ">
            <div className="tour-package-card margins">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <img src={p2} alt="" />
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 content-section">
                  <h5>Love in Bangkok Pattaya</h5>
                  <ul>
                    <li>
                      Welcome to Thailand, one of Asia’s most Visited cities,
                      full of culture and history. Upon arrival, driver meets
                      you at the airport and take you to the hotel in Pattaya.
                    </li>
                    <strong>4 Nights : 5 Days</strong>
                  </ul>
                  <div className="include-activity">
                    <div>
                      <h6>INCLUSIONS (CUSTOMISABLE)</h6>
                      <ul>
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          Hotels
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          activities
                        </span>
                        <br />
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          Transfers
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          Flights
                        </span>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="rate-deal">
                    <h4>Super Deal Price</h4>
                    <h6>
                      <strong>Starts From</strong>
                    </h6>
                    <p>
                      ₹47,498 /<span>42,498</span>
                    </p>
                    <h6> Per Person On Twin Sharing </h6>

                    <button
                      className="view-deal"
                      onClick={() =>
                        Navigate(`/ThailandTourPackagesPlan1/${"Love"}`)
                      }
                    >
                      More Details
                    </button>
                    <button className="enquery view-deal">
                      <a target="_blank" href="https://wa.link/mvkem1">
                        Enquery Now
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tour-packages">
          <div className="container ">
            <div className="tour-package-card margins">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <img src={p6} alt="" />
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 content-section">
                  <h5>The Best of Pattaya & Bangkok</h5>
                  <ul>
                    <li>
                      Discover the bright city life of Thailand on this short
                      trip. Witness the fun pub-hopping scenes on the magical
                      beaches of Pattaya and immerse yourself in the rich
                      culture of Bangkok.
                    </li>
                    <strong>5 Nights : 6 Days</strong>
                  </ul>
                  <div className="include-activity">
                    <div>
                      <h6>INCLUSIONS (CUSTOMISABLE)</h6>
                      <ul>
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          Hotels
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          activities
                        </span>
                        <br />
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          Transfers
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          <span class="material-symbols-outlined tick">
                            check_circle
                          </span>
                          Flights
                        </span>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="rate-deal">
                    <h4>Super Deal Price</h4>
                    <h6>
                      <strong>Starts From</strong>
                    </h6>
                    <p>
                      ₹15,498 /<span>20,000</span>
                    </p>
                    <h6> Per Person On Twin Sharing </h6>

                    <button
                      className="view-deal"
                      onClick={() =>
                        Navigate(`/ThailandTourPackagesPlan1/${"Bangkok"}`)
                      }
                    >
                      More Details
                    </button>
                    <button className="enquery view-deal">
                      <a target="_blank" href="https://wa.link/mvkem1">
                        Enquery Now
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ThailandTourPackages;
